<script setup>
import { WdsBadge, WdsIcon } from '@wds/components';

defineProps({
  checkInTime: {
    type: Object,
    required: true,
  },
  checkOutTime: {
    type: Object,
    required: true,
  },
  additionalRules: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const { getFacilityIcon } = useFacilities();
</script>

<template>
  <div class="stay-policy-container">
    <div class="checkin-checkout-rules">
      <div class="checkin-time">
        <WdsIcon
          asset="check-in"
          color="green"
          width="24"
          height="24"
        />
        <span>
          <strong>{{ $t('t_RWDCHECKIN') }}</strong>
          <p>{{ checkInTime?.startsAt }} - {{ checkInTime?.endsAt }}</p>
        </span>
      </div>
      <div class="checkout-time">
        <WdsIcon
          asset="check-out"
          color="red"
          width="24"
          height="24"
        />
        <span>
          <strong>{{ $t('t_RWDCHECKOUT') }}</strong>
          <p>{{ $t('t_UNTIL') }} {{ checkOutTime?.endsAt }}</p>
        </span>
      </div>
    </div>
    <ul class="additional-rule-list">
      <li
        v-for="rule in additionalRules"
        :key="rule"
        class="additional-rule-item"
      >
        <WdsBadge
          :icon="getFacilityIcon(rule)"
          class="additional-rule-badge"
          theme="blue-light"
          size="large"
        />
        <span>{{ $t(`t_${rule}`) }}</span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.stay-policy-container {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-m;

  .checkin-checkout-rules {
    display: inline-flex;
    justify-content: flex-start;
    padding-bottom: $wds-spacing-m;
    border-bottom: 1px solid $wds-color-ink-lighter;

    @include body-2-reg;

    .checkin-time,
    .checkout-time {
      display: inline-flex;
      align-items: center;
      gap: $wds-spacing-s;
    }

    .checkin-time {
      padding-right: $wds-spacing-m;
    }

    .checkout-time {
      padding-left: $wds-spacing-m;
      border-left: 1px solid $wds-color-ink-lighter;
    }
  }

  .additional-rule-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $wds-spacing-s $wds-spacing-xl;
    width: 100%;

    .additional-rule-item {
      display: inline-flex;
      gap: $wds-spacing-s;
      align-items: center;

      @include body-2-reg;

      .additional-rule-badge {
        padding: $wds-spacing-s;
        border-radius: 50%;
      }
    }
  }
}

@include desktop {
  .stay-policy-container {
    flex-direction: row;

    .checkin-checkout-rules {
      flex-direction: column;
      padding: $wds-spacing-s $wds-spacing-m 0 0;
      border-bottom: none;
      border-right: 1px solid $wds-color-ink-lighter;

      .checkin-time {
        padding-bottom: $wds-spacing-m;
        padding-inline: 0;
      }

      .checkout-time {
        padding-inline: 0;
        border: none;
      }
    }

    .additional-rule-list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      flex: 1;

      .additional-rule-item {
        margin-top: $wds-spacing-s;
      }
    }
  }
}
</style>
