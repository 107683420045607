<script setup>
import { WdsNavLink } from '@wds/components';

defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
});
// EVENTS
const emit = defineEmits(['viewMore']);

const { t } = useI18n();

// COMPUTED
const tabItems = computed(() => {
  // TODO: add conditional statement on property totalReviews

  return [
    {
      name: 'about',
      label: t('t_ABOUT'),
    },
    {
      name: 'houserules',
      label: t('t_HOUSERULES'),
    },
    {
      name: 'location',
      label: t('t_LOCATION'),
    },
    {
      name: 'reviews',
      label: t('t_REVIEWS'),
    },
  ];
});

const tabClicked = function (tabInfo) {
  emit('viewMore', tabInfo.tab);
};
</script>

<template>
  <component :is="tag">
    <div class="quick-nav-wrapper">
      <WdsNavLink
        v-for="item in tabItems"
        :key="`tab-${item.name}`"
        :label="item.label"
        :name="item.name"
        @click="tabClicked({ tab: item.name })"
      />
    </div>
  </component>
</template>

<style lang="scss" scoped>
.quick-nav-wrapper {
  display: flex;
  gap: $wds-spacing-m;
  margin: auto;
}
</style>
