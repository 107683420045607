<script setup>
import { SEGMENT_PAGE_DATA } from '@/config/analytics';
import { APPDOWNLOADBANNERLINKS } from '@/config/app-download-banner-links.js';
import mapboxConfig from '@/config/mapbox.js';
import { TRACKING_EMPTY } from '@/tracking/variables.js';
import { findPropertyTypeByKey } from '@/utils/propertyTypes';
import { WdsBreadCrumbs } from '@wds/components';

definePageMeta({ middleware: 'validate-property' });

const { $tracking } = useNuxtApp();
const {
  locale,
  t,
} = useI18n();

// ### COMPOSABLES
const route = useRoute();
const dateTime = useDateTime();
const { useRouteManager } = useRoutes();
const { useUrl } = useUrls();
const { buildSeo } = generateSeo();
const { useImage } = useImages();
const { useFormatter } = useFormatters();
const { useRating } = useRatings();
const {
  isExtraSmallScreen,
  isBelowMediumScreen,
  isSmallOrMediumScreen,
} = useMq();
const {
  getAppDownloadBannerVariation,
  isAppDownloadBannerDismissed,
} = useAppDownloadBanner();

// ### COMPOSABLES - APIs
const { getTopPropertiesByCityId } = useApiCities();
const { getPropertyPageTags } = useApiSeo();
const { getPropertyLocationTranslatedById } = useApiTranslator();
const { getSustainabilityLevel } = useSustainability();

// ### STORES
const languageStore = useLanguageStore();
const sessionStore = useSessionStore();
const locationStore = useLocationStore();
const searchStore = useSearchStore();
const testingStore = useTestingStore();

// ### A/B TESTING FEATURE TOGGLES
const experimentIgnoreAvailability = testingStore.hasEnabledFeature('static_ignoreAvailability');
const experimentNewSortOrder = testingStore.hasEnabledFeature('web_newSortOrder');
const experimentSustainability = testingStore.hasEnabledFeature('web_sustainabilityBadges');

// ### CONSTANTS INITIALIZATION
const locationKey = 'property';
const entryPageName = 'PROPERTY';
const gtmPageName = 'staticMicrosite';
const gtmClassification = 'microsite';
const segmentTracking = SEGMENT_PAGE_DATA.property;

const urlParams = route?.params || null;
const urlQuery = route?.query || null;
const AUTO_GENERATED_TEXT_STRING = '(Auto-translated from original language)';

// ### VARIABLES INITIALIZATION
const propertyDetailsActiveTab = ref(null);
const isPropertyDetailsOpen = ref(false);
const isLocationModalOpen = ref(false);
const isReviewsModalOpen = ref(false);

// ### ROUTE VALIDATION
const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);
const propertyTypeKey = propertyTypeObj?.key;

const propertyData = await useRouteManager.validatePropertyParam(propertyTypeObj, urlParams.propertyId);
const urlData = useRouteManager.getUrlData(
  urlParams.propertyType,
  null,
  null,
  null,
  null,
  urlParams.propertyId,
  urlParams.propertyName,
);
useRouteManager.redirectRoute(urlData, urlQuery);

// ### PROPERTY HREFLANGS
const urlTranslations = await getPropertyLocationTranslatedById(propertyData?.id);
const hrefUrls = useUrl.generateHrefLangForProperty(urlTranslations, propertyTypeObj.key, propertyData.id);

// ### PROPERTY CANONICAL URL
const propertyPageUrl = useUrl.getPropertyPageUrl({
  propertyTypeSlug: propertyTypeObj?.slug,
  propertyId: propertyData?.id,
  urlFriendlyProperty: propertyData?.urlFriendlyName,
});

// ### PROPERTY MAIN IMAGE
const propertyMainImage = propertyData?.images?.length ? propertyData?.images[0] : null;

// ### PROPERTY MINIMUM PRICE
const propertyMinPrice
  = propertyData.lowestPrice && propertyData.currency
    ? `${t('t_SCHEMAPRICESFROM')} ${useFormatter.formatCurrency(propertyData.lowestPrice, propertyData.currency)}`
    : '';

// ### PROPERTY MAPBOX LINK
const propertyMapLink = `${mapboxConfig.imageApiUrl}${propertyData?.location?.geoCoordinates?.longitude},${propertyData?.location?.geoCoordinates?.latitude},14,0/300x300?access_token=${mapboxConfig.microsite.token}`;

// ### PROPERTY BREADCRUMBS
const crumbs = [
  {
    label: t('t_A11YBCTITLEPROPERTYTYPE', { PROPERTYTYPE: t(propertyTypeObj?.transcode) }),
    title: t(propertyTypeObj.transcode),
    link: useUrl.getWorldPageUrl({ propertyTypeSlug: propertyTypeObj?.slug }),
  },
  {
    label: t('t_A11YBCTITLELOCATION', {
      LOCATIONNAME: propertyData?.location?.continent?.name,
      PROPERTYTYPE: t(propertyTypeObj?.transcode),
    }),
    title: t('t_A11YBCTEXTLOCATION', {
      LOCATIONNAME: propertyData?.location?.continent?.name,
      PROPERTYTYPE: t(propertyTypeObj?.transcode),
    }),
    link: useUrl.getContinentPageUrl({
      propertyTypeSlug: propertyTypeObj?.slug,
      urlFriendlyContinent: propertyData?.location?.continent?.urlFriendlyName,
    }),
  },
  {
    label: t('t_A11YBCTITLELOCATION', {
      LOCATIONNAME: propertyData?.location?.country?.name,
      PROPERTYTYPE: t(propertyTypeObj?.transcode),
    }),
    title: t('t_A11YBCTEXTLOCATION', {
      LOCATIONNAME: propertyData?.location?.country?.name,
      PROPERTYTYPE: t(propertyTypeObj?.transcode),
    }),
    link: useUrl.getCountryPageUrl({
      propertyTypeSlug: propertyTypeObj?.slug,
      urlFriendlyContinent: propertyData?.location?.continent?.urlFriendlyName,
      urlFriendlyCountry: propertyData?.location?.country?.urlFriendlyName,
    }),
  },
  {
    label: t('t_A11YBCTITLELOCATION', {
      LOCATIONNAME: propertyData?.location?.city?.name,
      PROPERTYTYPE: t(propertyTypeObj?.transcode),
    }),
    title: t('t_A11YBCTEXTLOCATION', {
      LOCATIONNAME: propertyData?.location?.city?.name,
      PROPERTYTYPE: t(propertyTypeObj?.transcode),
    }),
    link: useUrl.getCityPageUrl({
      propertyTypeSlug: propertyTypeObj?.slug,
      urlFriendlyContinent: propertyData?.location?.continent?.urlFriendlyName,
      urlFriendlyCountry: propertyData?.location?.country?.urlFriendlyName,
      urlFriendlyCity: propertyData?.location?.city?.urlFriendlyName,
    }),
  },
  {
    link: '#',
    title: propertyData.name,
  },
];

// ### MORE PROPERTIES - Other properties in the same city as the current property
const topPropertiesInCity = await getTopPropertiesByCityId(propertyTypeKey, propertyData?.location?.city?.id);
const morePropertiesInCity = topPropertiesInCity?.filter((c) => c.id !== propertyData.id)?.slice(0, 6) || [];

// ### PROPERTY SEO DATA
const seoData = await getPropertyPageTags(propertyTypeObj.key, propertyData.id);
const seoExtraData = {
  canonicalUrl: propertyPageUrl,
  ogImage: useImage.getImage(propertyMainImage?.medium) || '',
};
const tradReplacements = {
  PROPERTYNAME: propertyData?.name,
  LOCATIONNAME: propertyData?.name,
  CITYNAME: propertyData?.location?.city?.name,
  CITY: propertyData?.location?.city?.name,
  REVIEWSCOUNT: propertyData?.reviews?.totalCount || '',
  COUNTRYNAME: propertyData?.location?.country?.name,
  COUNTRY: propertyData?.location?.country?.name,
  PROPERTYTYPE: t(propertyTypeObj?.transcode_s),
  PROPERTYTYPES: t(propertyTypeObj?.transcode),
  YEAR: dateTime.today.year,
};
const seoMetaData = buildSeo.getMetaData(seoData, seoExtraData, tradReplacements);

// ### COMPUTED
const getPropertyFlatFacilites = computed(() => {
  return propertyData?.facilities?.flatMap((item) => [...item.facilities]) || [];
});

const getPropertyHeadingData = computed(() => {
  const propertyTypeInfo = findPropertyTypeByKey(propertyData.type);

  // HOSCARS WINNER
  const hoscarWinnerList = propertyData?.hoscars?.awards || [];
  const isHoscarWinnerForCurrentYear = hoscarWinnerList.findIndex((award) => award.year === dateTime.today.year) >= 0;
  const hoscarWinnerForCurrentYearTitle = isHoscarWinnerForCurrentYear
    ? `${dateTime.today.year} Hoscars ${t('t_WINNER')}`
    : false;

  return {
    propertyId: propertyData.id,
    propertyType: t(propertyTypeInfo.transcode_s),
    propertyName: propertyData?.name,
    propertyLocation: propertyData?.location,
    propertyRating: {
      averageRating: propertyData?.reviews?.totalAverageRating,
      numberOfReviews: propertyData?.reviews?.totalCount,
    },
    propertySustainability: getSustainabilityLevel(propertyData?.sustainability),
    propertyBadges: {
      awardWinner: hoscarWinnerForCurrentYearTitle,
      recommendedByHw: propertyData?.recommendedByHw ? t('t_RECOMMENDED') : false,
      freeWifi: propertyData?.badges?.find((b) => b.id === '90'),
      breakFastIncluded: propertyData?.badges?.find((b) => b.id === '1'),
    },
    propertyTotalFacilities: getPropertyFlatFacilites.value?.length || 0,
    propertyCanxPolicyDays: propertyData?.policies?.freeCancellation?.isAvailable
      ? propertyData?.policies?.freeCancellation?.daysInAdvance
      : 0,
  };
});

const getPropertyFacilitiesSnippetData = computed(() => {
  return { facilities: getPropertyFlatFacilites.value.slice(0, 8) || [] };
});

const propertyHasFacilities = computed(() => {
  return toValue(getPropertyFacilitiesSnippetData)?.facilities?.length;
});

const getPropertyHouseRulesData = computed(() => {
  return {
    checkInTime: propertyData?.policies?.checkIn,
    checkOutTime: propertyData?.policies?.checkOut,
    additionalRules: propertyData?.policies?.additional,
  };
});

const getMorePropertiesInCityListing = computed(() => morePropertiesInCity || []);

const hasRatingAndReviews = computed(() => {
  return propertyData?.reviews?.totalAverageRating > 0 && propertyData?.reviews?.latest?.length;
});

const getPropertyRatingAndReviewsData = computed(() => {
  return {
    propertyId: propertyData.id,
    propertyRating: {
      averageRating: propertyData?.reviews?.totalAverageRating,
      numberOfReviews: propertyData?.reviews?.totalCount,
      breakdown: propertyData?.reviews?.averageRatings,
    },
    propertyLatestReviews: propertyData?.reviews?.latest?.map((review) => {
      const reviewScore = review.rating || 0;
      const reviewDescription = [t(`t_${review.guestGender.toUpperCase().replace(/ /g, '')}`) || ''];
      reviewDescription.push(review.guestAgeGroup || '');
      reviewDescription.push(review.guestCountry || '');
      const reviewDate = review.stayed
        ? dateTime.formatToHumanReadableShortDate(review.stayed, languageStore.getCurrentLanguage.iso, 'month|year')
        : '';

      return {
        date: `${t('t_STAYEDINDATE', { DATE: reviewDate })}`,
        text: review.review,
        rating: Number(reviewScore.toFixed(1)),
        author: {
          userName: review.guestFirstname,
          description: reviewDescription.join(', '),
          countryCode: review.guestCountryCode,
          countryName: review.guestCountry,
          ageRange: review.guestAgeGroup,
          groupTypeCode: review.guestGender,
          flagURL: `https://a.hwstatic.com/hw/flags/${review.guestCountryCode}.svg`,
          color: 'pink',
        },
      };
    }),
  };
});

const propertyDetailsTabsData = computed(() => {
  return {
    about: { description: propertyData?.descriptions?.main || '' },
    facilities: propertyData?.facilities,
    houserules: {
      ...toValue(getPropertyHouseRulesData),
      thingsToNote: propertyData?.policies?.thingsToNote,
      cancellationPolicy: propertyData?.policies?.cancellationPolicy,
    },
    hoscars: propertyData?.hoscars,
    sustainability: getSustainabilityLevel(propertyData?.sustainability),
  };
});

const getCrumbsShownByViewport = computed(() => {
  return toValue(isExtraSmallScreen) ? 2 : null;
});

const getAppDownloadDetails = computed(() => {
  const searchParamsCheckIn = dateTime.formatToFlat(searchStore.getSearchCheckIn);
  const searchParamsCheckOut = dateTime.formatToFlat(searchStore.getSearchCheckOut);

  const searchParamsGroupType = searchStore.getSearchGroupType?.value || null;

  let appLink = `py/${propertyData.id}?from=${searchParamsCheckIn}&to=${searchParamsCheckOut}&guests=${searchStore.getSearchNumberOfGuests}`;

  if (searchParamsGroupType) {
    appLink += `&groupType=${searchParamsGroupType}&groupAgeRange=${searchStore.getSearchGroupAge}`;
  }

  return {
    page: 'property',
    linkPaths: {
      a: APPDOWNLOADBANNERLINKS?.PROPERTY?.VARIATION_A,
      b: APPDOWNLOADBANNERLINKS?.PROPERTY?.VARIATION_B,
    },
    linkParams: appLink,
  };
});

// ### METHODS
const togglePropertySectionPopup = function (tab = null) {
  switch (tab) {
    case 'location':
      isLocationModalOpen.value = !isLocationModalOpen.value;
      break;
    case 'reviews':
      isReviewsModalOpen.value = !isReviewsModalOpen.value;
      break;
    case null:
      isPropertyDetailsOpen.value = false;
      break;
    default:
      propertyDetailsActiveTab.value = tab;
      isPropertyDetailsOpen.value = true;
      break;
  }
};

useHead(() => ({
  htmlAttrs: { lang: locale?.value },
  link: [
    {
      rel: 'canonical',
      href: seoMetaData?.canonicalUrl,
    },
  ].concat(hrefUrls),
}));

useSeoMeta({
  title: () => seoMetaData?.title,
  ogTitle: () => seoMetaData?.ogTitle,
  description: () => seoMetaData?.description,
  ogDescription: () => seoMetaData?.ogDescription,
  ogUrl: () => seoMetaData?.canonicalUrl,
  ogImage: () => seoMetaData?.ogImage,
  ogImageAlt: () => seoMetaData?.ogDescription,
  ogType: () => 'website',
  keywords: () => seoMetaData?.keywords,
  robots: () => 'index, follow, maxImagePreview:large',
  twitterCard: () => 'summary_large_image',
  twitterTitle: () => seoMetaData?.ogTitle,
  twitterDescription: () => seoMetaData?.ogDescription,
  twitterImage: () => seoMetaData?.ogImage,
  twitterImageAlt: () => seoMetaData?.ogDescription,
});

// ### LOCATION
locationStore.setLocation(locationKey, {
  id: propertyData?.id,
  name: `${propertyData?.name}, ${propertyData?.location?.city?.name}, ${propertyData?.location?.country?.name}`,
});

// ### SCHEMAS
useSchemaOrg([
  defineBreadcrumb({
    itemListElement: crumbs?.map((c) => ({
      name: c?.title,
      item: c?.link,
    })),
  }),
  {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    'name': propertyData.name,
    'url': seoMetaData?.canonicalUrl,
    'description': seoMetaData?.ogDescription,
    'priceRange': propertyMinPrice,
    'aggregateRating': {
      '@type': 'AggregateRating',
      'name': propertyData.name,
      'ratingValue': propertyData?.reviews?.totalAverageRating,
      'reviewCount': propertyData?.reviews?.totalCount,
      'ratingCount': propertyData?.reviews?.totalCount,
      'bestRating': 10,
      'worstRating': 0,
    },
    'hasMap': propertyMapLink,
    'image': seoMetaData.ogImage,
    'photo': {
      '@type': 'ImageObject',
      'name': propertyData.name,
      'url': seoMetaData.ogImage,
    },
    'address': {
      '@type': 'PostalAddress',
      'postalCode': propertyData?.location?.address?.postalCode,
      'addressLocality': propertyData?.location?.city?.name,
      'addressRegion': propertyData?.location?.city?.name,
      'streetAddress': propertyData?.location?.address?.line1,
      'addressCountry': propertyData?.location?.country?.name,
    },
    'geo': {
      '@type': 'GeoCoordinates',
      'latitude': propertyData?.location?.geoCoordinates?.latitude,
      'longitude': propertyData?.location?.geoCoordinates?.longitude,
    },
  },
]);

// ### TRACKING
sessionStore.setEntryPoint(entryPageName);

// ### MOUNTED
onMounted(() => {
  const trackingPropertyDescriptionAutoTranslated
    = propertyData?.descriptions?.main.includes(AUTO_GENERATED_TEXT_STRING);

  const trackingSearchCheckInDate = dateTime.formatToFlat(searchStore.getSearchCheckIn);
  const trackingSearchCheckInDateStripped = trackingSearchCheckInDate.replace(/-/g, '');
  const trackingSearchCheckOutDate = dateTime.formatToFlat(searchStore.getSearchCheckOut);
  const trackingSearchCheckOutDateStripped = trackingSearchCheckOutDate.replace(/-/g, '');

  const trackingPropertyClassName = useRating
    .getRatingClass(propertyData?.reviews?.totalAverageRating, propertyData.isNew)
    .toLowerCase();
  const trackingPropertyDepositPercentage = propertyData.depositPercentage;
  const trackingPropertyType = propertyTypeObj.type.toLowerCase();

  const propertySustainabilityInfo = getSustainabilityLevel(propertyData?.sustainability);
  const trackingPropertySustainabilityTier = propertySustainabilityInfo?.tier
    ? `SUSTAINABILITY_TIER_${propertySustainabilityInfo?.tier}`
    : null;

  const trackingInfo = {
    gtm: {
      gtmPageName,
      gtmClassification,
      gtmPropertyType: trackingPropertyType,
      gtmCanonicalUrl: seoExtraData.canonicalUrl,
      gtmContinent: propertyData?.tracking?.continent,
      gtmCountry: propertyData?.tracking?.country,
      gtmCity: propertyData?.tracking?.city,
      gtmPropertyName: propertyData.name,
      gtmPropertyClass: trackingPropertyClassName,
      gtmHostelName: propertyData.name,
      gtmHostelNumber: propertyData.id,
      gtmDepositServiceFee: trackingPropertyDepositPercentage,
      gtmSearchKeywords: searchStore.getSearchPhrase,
      gtmArrivalDate: trackingSearchCheckInDate,
      gtmArrivalDateStripped: trackingSearchCheckInDateStripped,
      gtmDepartureDate: trackingSearchCheckOutDate,
      gtmDepartureDateStripped: trackingSearchCheckOutDateStripped,
      gtmPropertyIds: propertyData.id,
      gtmRoomTypes: 'zero',
      gtmPrivateDormSplit: 'zero',
      gtmSustainableLevel: trackingPropertySustainabilityTier,
    },
    segment: {
      event_name: segmentTracking.VIEWED,
      page_type: segmentTracking.TYPE,
      property_type: trackingPropertyType,
      destination_city: propertyData.tracking.city,
      destination_city_id: propertyData?.location?.city?.id?.toString(),
      destination_country: propertyData?.tracking?.country,
      destination_continent: propertyData?.tracking?.continent,
      product_id: propertyData.id?.toString(),
      sku: propertyData.id?.toString(),
      property_name: propertyData.tracking.name,
      property_deposit_percent: trackingPropertyDepositPercentage || TRACKING_EMPTY,
      property_class: trackingPropertyClassName || TRACKING_EMPTY,
      property_price_from: propertyData?.lowestPrice || 0,
      property_currency: propertyData?.currency,
      position: TRACKING_EMPTY,
      machine_translation_flag: trackingPropertyDescriptionAutoTranslated ? true : undefined,
    },
  };

  if (!isAppDownloadBannerDismissed()) {
    trackingInfo.segment.app_download_banner_cohort = getAppDownloadBannerVariation(locationKey);
  }

  $tracking?.trackOnPageLoad(trackingInfo.gtm, trackingInfo.segment);

  $tracking?.onPropertyIgnoreAvailability({ parameter1: experimentIgnoreAvailability ? 'variation' : 'control' });

  $tracking?.onNewSortOrderExperiment({ parameter1: experimentNewSortOrder ? 'variation' : 'control' });

  $tracking?.onSustainabilityExperiment({ parameter1: experimentSustainability ? 'variation' : 'control' });

  if (trackingPropertyDescriptionAutoTranslated) {
    $tracking?.onMachineTranslationEvent({ parameter1: gtmPageName });
  }
});
</script>

<script>
/* eslint-disable */
import { ca } from '@/lib/channelAttribution.js';

const entryPageNameJs = 'PROPERTY';

if (import.meta.client) {
  ca?.setChannelAttribution(null, entryPageNameJs);
}
/* eslint-enable */
</script>

<template>
  <ClientOnly>
    <LazyCommonHwAppDownloadBanner v-bind="getAppDownloadDetails" />
  </ClientOnly>

  <CommonHwHeader
    :single-bar="true"
    :sticky="false"
    class="property-header"
  />

  <PropertyHwImageGallery
    :image-list="propertyData?.images"
    :property-name="propertyData?.name"
    :video-list="propertyData?.videos"
    class="hw-wrapper ms-section image-gallery-section"
  />

  <PropertyHwHeading
    class="hw-wrapper ms-section"
    v-bind="getPropertyHeadingData"
    @view-more="togglePropertySectionPopup"
  />

  <section class="quick-nav-section">
    <PropertyHwQuickNav
      v-if="!isBelowMediumScreen"
      tag="div"
      class="hw-wrapper ms-section quick-nav-component"
      @view-more="togglePropertySectionPopup"
    />
  </section>

  <section class="hw-wrapper ms-section property-main-section">
    <article class="main-content">
      <PropertyHwAbout
        tag="div"
        class="about-section"
        :description="propertyData?.descriptions?.main"
        @view-more="togglePropertySectionPopup"
      />

      <PropertyHwHouseRules
        tag="div"
        class="house-rules-section"
        v-bind="getPropertyHouseRulesData"
        @view-more="togglePropertySectionPopup"
      />

      <PropertyHwFacilities
        v-if="propertyHasFacilities"
        tag="div"
        class="facilities-section"
        v-bind="getPropertyFacilitiesSnippetData"
        @view-more="togglePropertySectionPopup"
      />

      <PropertyHwReviews
        v-if="hasRatingAndReviews"
        tag="div"
        class="rating-and-reviews-section"
        v-bind="getPropertyRatingAndReviewsData"
        @display-modal="togglePropertySectionPopup"
      />

      <PropertyHwMap
        tag="div"
        class="location-section"
        :property-location="propertyData?.location"
        @display-modal="togglePropertySectionPopup"
      />

      <PropertyHwHostelworldSays
        v-if="propertyData?.hostelworldSays"
        tag="div"
        class="hostelworld-says-section"
        :description="propertyData?.hostelworldSays"
      />

      <PropertyHwMorePropertiesInCity
        tag="div"
        class="more-properties-section"
        :city-data="propertyData?.location"
        :property-list="getMorePropertiesInCityListing"
        :property-type-obj="propertyTypeObj"
      />
    </article>
    <aside class="availability-side-bar">
      <div class="availability-side-rail">
        <PropertyHwAvailabilityPanel
          class="availability-component"
          :property-id="propertyData?.id"
          :property-slug="propertyData?.urlFriendlyName"
          :property-city="propertyData?.location?.city?.urlFriendlyName"
        />
      </div>
    </aside>
  </section>

  <section class="hw-wrapper ms-section property-breadcrumbs">
    <WdsBreadCrumbs
      :breadcrumbs="crumbs"
      :max-crumbs-shown="getCrumbsShownByViewport"
      :reversed="false"
    />
  </section>

  <LazyPropertyHwReviewsModal
    v-if="isReviewsModalOpen"
    :apply-sheet="isSmallOrMediumScreen"
    :property-name="propertyData?.name"
    v-bind="getPropertyRatingAndReviewsData"
    @close-property-reviews="togglePropertySectionPopup"
  />

  <LazyPropertyHwMapLocationModal
    v-if="isLocationModalOpen"
    :apply-sheet="isSmallOrMediumScreen"
    :property-location="propertyData?.location"
    :property-type-obj="propertyTypeObj"
    @close-property-location="togglePropertySectionPopup"
  />

  <LazyPropertyHwDetailsPopup
    v-if="isPropertyDetailsOpen"
    :active-tab="propertyDetailsActiveTab"
    :apply-sheet="isSmallOrMediumScreen"
    :property-type-obj="propertyTypeObj"
    :tabs-data="propertyDetailsTabsData"
    @close-popup="togglePropertySectionPopup"
  />

  <CommonHwLanguagePicker v-show="languageStore.isLanguagePickerOpen" />
</template>

<style lang="scss" scoped>
.property-header {
  margin-bottom: 0;
}

.ms-section {
  margin: 0 auto $wds-spacing-m;
  padding-inline: $wds-spacing-m;

  @include tablet {
    padding-inline: $wds-spacing-l;
  }
}

.image-gallery-section {
  padding-inline: $wds-spacing-m 0;

  @include tablet {
    padding-inline: $wds-spacing-l 0;
  }

  @include desktop-large {
    padding-inline: $wds-spacing-l;
  }
}

.heading-section {
  @include tablet {
    padding-top: $wds-spacing-s;
  }
}

.quick-nav-section {
  position: sticky;
  top: 0;
  background-color: $wds-color-ink-lightest;
  z-index: 1;

  .quick-nav-component {
    padding-inline: $wds-spacing-s;
  }
}

.about-section,
.house-rules-section,
.facilities-section,
.rating-and-reviews-section,
.location-section,
.hostelworld-says-section,
.more-properties-section {
  :deep(h2) {
    @include title-5-bld;

    margin-bottom: $wds-spacing-m;

    @include desktop {
      @include title-3-bld;
    }
  }

  &::after {
    content: ' ';
    display: block;
    height: 1px;
    width: 100%;
    background-color: $wds-color-ink-lighter;
    margin-block: $wds-spacing-l;
  }
}

.rating-and-reviews-section,
.location-section,
.hostelworld-says-section,
.more-properties-section {
  &::after {
    height: 0;
    margin-block: 0 $wds-spacing-xxl;
  }
}

.property-main-section {
  margin-top: $wds-spacing-xxl;
  margin-bottom: $wds-spacing-xxl;

  .availability-side-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $wds-z-index-toast;
  }

  @include tablet-large {
    position: relative;
    display: flex;
    gap: $wds-spacing-l;

    .main-content {
      flex: 1;
      overflow: hidden;
    }

    .availability-side-bar {
      display: block;
      flex-basis: wds-rem(360px);
      position: relative;

      .availability-side-rail {
        position: absolute;
        inset: 0;

        .availability-component {
          position: sticky;
          top: $wds-spacing-xxxl;
        }
      }
    }
  }

  @include desktop-large {
    .availability-side-bar .availability-side-rail {
      inset: wds-rem(-165px) 0 0;
    }
  }
}
</style>
