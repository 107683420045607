<script setup>
import { findPropertyTypeByKey } from '@/utils/propertyTypes';
import { WdsBadge, WdsIcon, WdsIllustration, WdsRating } from '@wds/components';

const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
  propertyId: {
    type: Number,
    required: true,
  },
  propertyType: {
    type: String,
    required: true,
  },
  propertyName: {
    type: String,
    required: true,
  },
  propertyLocation: {
    type: Object,
    required: true,
  },
  propertyRating: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  propertyBadges: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  propertySustainability: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  propertyTotalFacilities: {
    type: Number,
    required: false,
    default: 0,
  },
  propertyCanxPolicyDays: {
    type: Number,
    required: false,
    default: 0,
  },
});
// EVENTS
const emit = defineEmits(['viewMore']);
const { t } = useI18n();

// STORES
const routeStore = useRouteStore();

// COMPOSABLES
const { useRating } = useRatings();
const { useUrl } = useUrls();
const {
  isBelowMediumScreen,
  isLargeScreen,
} = useMq();

const propertyTypeObj = findPropertyTypeByKey(toValue(routeStore.getPropertyType));
const ratingScoreTranslations = toValue(useRating?.getRatingScoreKeywordTranslations);
const propertyCityPageUrl = useUrl.getCityPageUrl({
  propertyTypeSlug: t(propertyTypeObj.slug),
  urlFriendlyContinent: props.propertyLocation?.continent?.urlFriendlyName,
  urlFriendlyCountry: props.propertyLocation?.country?.urlFriendlyName,
  urlFriendlyCity: props.propertyLocation?.city?.urlFriendlyName,
});
const propertyCountryPageUrl = useUrl.getCountryPageUrl({
  propertyTypeSlug: t(propertyTypeObj.slug),
  urlFriendlyContinent: props.propertyLocation?.continent?.urlFriendlyName,
  urlFriendlyCountry: props.propertyLocation?.country?.urlFriendlyName,
});

// COMPUTED
const hasCancellationPolicy = computed(() => {
  return props.propertyCanxPolicyDays > 0;
});

// METHODS
const toggleMapPopup = function () {
  emit('viewMore', 'location');
};
const toggleFacilitesPopup = function () {
  emit('viewMore', 'facilities');
};
const toggleReviewsPopup = function () {
  // @TODO: To be implemented on STATICWEB-150
  // eslint-disable-next-line no-alert
  alert('To be implemented on STATICWEB-150');
};

const toggleSustainabilityPopup = function () {
  emit('viewMore', 'more');
};
</script>

<template>
  <component
    :is="tag"
    class="heading-section"
  >
    <div class="heading-main-info">
      <div
        v-if="isBelowMediumScreen"
        class="mi-property-type"
      >
        {{ propertyType }}
      </div>
      <h1 class="mi-property-name">
        {{ propertyName }}
      </h1>
      <div class="mi-property-rating">
        <WdsRating
          :direction="isBelowMediumScreen ? 'column' : 'row'"
          :score="propertyRating?.averageRating"
          :show-keyword="!isBelowMediumScreen"
          :total-reviews="propertyRating?.numberOfReviews"
          :translations="ratingScoreTranslations"
          align="right"
          :size="isLargeScreen ? 'large' : 'medium'"
          @click="toggleReviewsPopup"
        />
      </div>
      <div class="mi-property-location">
        <template v-if="isBelowMediumScreen">
          <span class="location-box-mobile">
            <WdsIcon asset="location-pin" />
            <a
              class="text-link underline"
              href="#map"
              @click.prevent="toggleMapPopup"
            >{{ propertyLocation?.city?.name }} &middot; {{ $t('t_MAPVIEW') }}</a>
          </span>
        </template>
        <template v-else>
          <span>
            {{ propertyType }}
          </span>
          <span>
            <a
              :href="propertyCityPageUrl"
              class="text-link"
            >{{ propertyLocation?.city?.name }}</a>,
            <a
              :href="propertyCountryPageUrl"
              class="text-link"
            >{{ propertyLocation?.country?.name }}</a>
          </span>
          <span>
            <a
              class="text-link underline"
              href="#map"
              @click.prevent="toggleMapPopup"
            >{{ $t('t_MAPVIEW') }}</a>
          </span>
          <span>
            {{ $t('t_FROMCITYCENTER', { DISTANCE: propertyLocation?.cityCenterDistance }) }}
          </span>
        </template>
      </div>
    </div>
    <ul class="heading-badges-and-facilities">
      <li
        v-if="propertyBadges?.awardWinner"
        class="badge"
      >
        <WdsIllustration
          :title="propertyBadges?.awardWinner"
          asset="trophy"
          folder="sm"
          height="24"
          width="24"
        />
      </li>
      <li
        v-if="propertyBadges?.recommendedByHw"
        class="badge"
      >
        <WdsIllustration
          :title="propertyBadges?.recommendedByHw"
          asset="thumb"
          folder="sm"
          height="24"
          width="24"
        />
      </li>
      <li
        v-if="propertySustainability?.tier"
        class="sustainability"
        @click="toggleSustainabilityPopup"
      >
        <WdsBadge
          :icon="propertySustainability?.icon"
          class="sustainability-badge"
          size="large"
          theme="green-light"
        />
        <span>{{ propertySustainability?.title }}</span>
      </li>
      <li
        v-if="propertyBadges?.freeWifi?.id"
        class="facility"
      >
        <WdsBadge
          icon="wi-fi"
          size="large"
          theme="blue-light"
        />
        <span>{{ $t(`t_${propertyBadges?.freeWifi?.transCode}`) }}</span>
      </li>
      <li
        v-if="propertyBadges?.breakFastIncluded?.id"
        class="facility"
      >
        <WdsBadge
          icon="breakfast"
          size="large"
          theme="blue-light"
        />
        <span>{{ $t(`t_${propertyBadges?.breakFastIncluded?.transCode}`) }}</span>
      </li>
      <li
        v-if="propertyTotalFacilities"
        class="link"
      >
        <a
          class="text-link underline"
          href="#facilities"
          @click.prevent="toggleFacilitesPopup"
        >{{ $t('t_VIEWALLFACILITIES', { NUMBERFACILITIES: propertyTotalFacilities }) }}</a>
      </li>
    </ul>
    <div
      v-if="propertyBadges?.awardWinner"
      class="heading-award-winner"
    >
      <WdsIllustration
        :title="propertyBadges?.awardWinner"
        asset="trophy"
        folder="sm"
        height="24"
        width="24"
      />
      <span>{{ propertyBadges?.awardWinner }}</span>
    </div>
    <div
      v-if="hasCancellationPolicy"
      class="heading-free-canx"
    >
      <WdsIllustration
        asset="calendar-confirmation"
        folder="sm"
      />
      <span v-html="$t(`t_FREECANCELLATIONDIAVARIABLE`, { DAYSINADVANCE: propertyCanxPolicyDays })" />
    </div>
  </component>
</template>

<style lang="scss" scoped>
.heading-section {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-l;

  .heading-main-info {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: $wds-spacing-s;
    grid-template-areas:
      'type type'
      'name rating'
      'location rating';

    .mi-property-type {
      grid-area: type;
    }

    .mi-property-name {
      grid-area: name;

      @include title-4-bld;
    }

    .mi-property-rating {
      grid-area: rating;
    }

    .mi-property-location {
      grid-area: location;
      display: inline-flex;

      > * {
        &::after {
          content: '\b7';
          margin-inline: $wds-spacing-s;
        }

        &:last-child::after {
          content: '';
        }
      }

      .location-box-mobile {
        display: inline-flex;
        align-items: center;
        gap: $wds-spacing-s;
      }
    }
  }

  .heading-badges-and-facilities {
    display: flex;
    gap: $wds-spacing-m $wds-spacing-s;
    overflow-x: auto;

    @include no-scrollbar;

    .badge,
    .link {
      display: none;
    }

    .sustainability,
    .facility {
      display: inline-flex;
      gap: $wds-spacing-s;
      align-items: center;
      justify-content: center;
      margin-right: $wds-spacing-s;
      white-space: nowrap;

      @include body-2-reg;
    }

    .sustainability {
      cursor: pointer;

      .sustainability-badge {
        background-color: $wds-color-green-lightest;
        fill: $wds-color-green;
      }
    }
  }

  .heading-award-winner {
    display: flex;
    align-items: center;
    gap: $wds-spacing-m;
    padding: $wds-spacing-m;
    border-radius: $wds-border-radius-m;
    box-shadow: inset 0 0 0 wds-rem(1px) $wds-color-ink-lighter;
    width: 100%;
    max-width: wds-rem(328px);

    > span {
      flex-grow: 1;

      @include body-2-reg;
    }
  }

  .heading-free-canx {
    display: flex;
    align-items: center;
    gap: $wds-spacing-s;

    @include body-3-reg;
  }

  @include tablet-large {
    .heading-badges-and-facilities {
      flex-wrap: wrap;

      .badge {
        display: inline-block;
        padding: $wds-spacing-s;
        border-radius: wds-rem(48px);
        box-shadow: inset 0 0 0 wds-rem(1px) $wds-color-ink-lighter;
      }

      .link {
        display: inline-flex;
        gap: $wds-spacing-s;
        align-items: center;
        justify-content: center;
        margin-right: $wds-spacing-s;

        @include body-1-reg;
      }
    }

    .heading-award-winner {
      display: none;
    }
  }

  @include desktop {
    .heading-main-info {
      .mi-property-name {
        @include title-1-bld;
      }
    }
  }
}
</style>
