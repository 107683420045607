export const FACILITY_ICONS = {
  '24HOURRECEPTION': 'reception',
  '24HOURSECURITY': 'security',
  'ADAPTORS': 'adaptor',
  'AGERESTRICTION': 'restriction',
  'AIRCONDITIONING': 'air-conditioning',
  'AIRPORTTRANSFERS': 'transfer',
  'ATM': 'atm',
  'BAR': 'bar',
  'BBQ': 'bbq',
  'BICYCLEHIRE': 'bicycle',
  'BICYCLEPARKING': 'bicycle',
  'BOARDGAMES': 'board-games',
  'BOOKEXCHANGE': 'book',
  'BREAKFASTINCLUDED': 'breakfast',
  'BREAKFASTNOTINCLUDED': 'breakfast',
  'CABLETV': 'tv',
  'CAF': 'breakfast',
  'CAFE': 'breakfast',
  'CARBONMONOXIDEDETECTOR': 'smoke-detector',
  'CARDPHONES': 'phone',
  'CCTVINCOMMONAREAS': 'cctv',
  'CEILINGFAN': 'ceiling-fan',
  'CHARGINGPLUGS': 'adaptor',
  'CHILDFRIENDLY': 'children',
  'CHILDRENSPLAYAREA': 'childrens-play',
  'COMMONROOM': 'sofa',
  'COOKER': 'cooker',
  'COTS': 'cots',
  'COTSAVAILABLE': 'cots',
  'COWORKINGSPACE': 'working',
  'CREDITCARDSACCEPTED': 'credit-card',
  'CURFEW': 'clock',
  'CURRENCYEXCHANGE': 'currency-exchange',
  'DIRECTDIALTELEPHONE': 'phone',
  'DISHWASHER': 'dishwasher',
  'DRYER': 'laundry',
  'DRYINGMACHINE': 'laundry',
  'DVDS': 'dvd',
  'ELEVATOR': 'elevator',
  'EXPRESSCHECKINOUT': 'express-check-in-out',
  'FAXSERVICE': 'phone',
  'FILTEREDWATERDISPENSER': 'water',
  'FIREEXTINGUISHERS': 'fire-extinguishers',
  'FIRSTAIDKITS': 'first-aid',
  'FLEXIBLENRR': 'change-date',
  'FREEAIRPORTTRANSFERS': 'transfer',
  'FREECITYMAPS': 'map',
  'FREECITYTOUR': 'city',
  'FREEINTERNETACCESS': 'internet',
  'FREEIRONIRONINGBOARD': 'iron',
  'FREELUGGAGESTORAGE': 'luggage',
  'FREEPARKING': 'parking',
  'FREESECURITYLOCKERS': 'locker',
  'FREEWIFI': 'wi-fi',
  'FRIDGEFREEZER': 'fridge',
  'FUSBALL': 'foosball',
  'GAMESROOM': 'games-room',
  'GAMINGCONSOLES': 'gamepad',
  'GARDEN': 'garden',
  'GYM': 'gym',
  'HAIRDRYERS': 'hair-dryer',
  'HAIRDRYERSFORHIRE': 'hair-dryer',
  'HOTSHOWERS': 'shower',
  'HOTTUB': 'hot-tube',
  'HOUSEKEEPING': 'housekeeping',
  'INDOORSWIMMINGPOOL': 'swimming-pool',
  'INTERNETACCESS': 'internet',
  'INTERNETCAF': 'internet',
  'IRONIRONINGBOARD': 'iron',
  'JOBSBOARD': 'jobs-board',
  'KETTLE': 'kettle',
  'KEYCARDACCESS': 'key-card',
  'KITCHEN': 'pan',
  'LATECHECKOUT': 'night',
  'LAUNDRYFACILITIES': 'laundry',
  'LINENINCLUDED': 'linen',
  'LINENNOTINCLUDED': 'linen',
  'LOCKERS': 'safe-deposit-box',
  'LOUNGEROOM': 'sofa',
  'LUGGAGESTORAGE': 'luggage',
  'MASSAGE': 'massage',
  'MEALSAVAILABLE': 'meal',
  'MEETINGROOM': 'meeting-room',
  'MICROWAVE': 'microwave',
  'MINIBAR': 'minibar',
  'MINISUPERMARKET': 'supermarket',
  'NIGHTCLUB': 'nightclub',
  'NOCURFEW': 'clock',
  'NONSMOKING': 'no-smoke',
  'NOTCHILDFRIENDLY': 'children',
  'OUTDOORSWIMMINGPOOL': 'swimming-pool',
  'OUTDOORTERRACE': 'terrace',
  'PAIDIRONIRONINGBOARD': 'iron',
  'PAIDLUGGAGESTORAGE': 'luggage',
  'PAIDPARKING': 'parking',
  'PAIDSECURITYLOCKERS': 'locker',
  'PANORAMICVIEWS': 'panoramic-views',
  'PARKING': 'parking',
  'PETFRIENDLY': 'pet',
  'PLAYSTATION': 'gamepad',
  'POOLTABLE': 'pool-table',
  'POSTALSERVICE': 'envelope',
  'POTSANDPANS': 'pan',
  'RAMPS': 'ramps',
  'READINGLIGHT': 'reading-light',
  'RECEPTIONLIMITEDHOURS': 'reception',
  'RECYCLINGBINS': 'recycling',
  'RESTAURANT': 'utensils',
  'ROOFTOP': 'rooftop',
  'ROOFTOPBAR': 'rooftop',
  'SAFEDEPOSITBOX': 'safe-deposit-box',
  'SANITISATIONBADGE': 'sanitation-badge',
  'SAUNA': 'sauna',
  'SHOWERCHAIRS': 'shower-chairs',
  'SHUTTLEBUS': 'transfer',
  'SINK': 'sink',
  'SMARTTV': 'smart-tv',
  'SMOKEALARMS': 'smoke-detector',
  'SMOKEDETECTOR': 'smoke-detector',
  'SOCIALBALCONY': 'balcony',
  'STEAMROOM': 'sauna',
  'STOVE': 'cooker',
  'SWIMMINGPOOL': 'swimming-pool',
  'TABLETENNIS': 'table-tennis',
  'TAXESINCLUDED': 'money',
  'TAXESNOTINCLUDED': 'money',
  'TEACOFFEEMAKINGFACILITIES': 'breakfast',
  'TOASTER': 'toaster',
  'TOURSTRAVELDESK': 'travel-desk',
  'TOWELSFORHIRE': 'towel',
  'TOWELSINCLUDED': 'towel',
  'TOWELSNOTINCLUDED': 'towel',
  'UTENSILS': 'utensils',
  'VENDINGMACHINES': 'vendingmachines',
  'WAKEUPCALLS': 'wake-up-call',
  'WASHINGMACHINE': 'laundry',
  'WHEELCHAIRACCESSIBLE': 'wheelchair',
  'WHEELCHAIRACCESSIBLEBATHROOMS': 'wheelchair',
  'WIDERDOORWAYS': 'door',
  'WIFI': 'wi-fi',
  'WII': 'gamepad',
};
