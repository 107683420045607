<script setup>
import propertyConfig from '@/config/property.js';
import { onClickOutside } from '@vueuse/core';
import { WdsButtonGhost, WdsIcon, WdsPillFloating, WdsPopup } from '@wds/components';

const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
  propertyName: {
    type: String,
    required: true,
  },
  imageList: {
    type: Array,
    required: true,
  },
  videoList: {
    type: Array,
    required: true,
  },
});

const { $tracking } = useNuxtApp();

// REF
const galleryAtEnd = ref(false);
const galleryList = ref(null);
const showGalleryModal = ref(false);
const showLargeMedia = ref(false);
const lightBoxModal = ref(null);
const selectedLargeMedia = ref(null);
const selectedLargeMediaId = ref(null);

onClickOutside(lightBoxModal, () => closeLightbox(), {});

// COMPOSABLES
const { t } = useI18n();
const {
  isSmallOrMediumScreen,
  isLargeScreen,
} = useMq();

// COMPUTED
const getGalleryNavActionData = computed(() => {
  return {
    text: toValue(galleryAtEnd) ? t('t_VIEWALL') : '',
    iconStart: toValue(galleryAtEnd) ? '' : 'chevron-right',
    iconEnd: toValue(galleryAtEnd) ? 'images' : '',
    ariaLabel: toValue(galleryAtEnd) ? t('t_VIEWALL') : '',
  };
});

const mediaItems = computed(() => {
  return [...props.videoList, ...props.imageList];
});

const isFirstLargeMedia = computed(() => {
  return selectedLargeMediaId.value === 0;
});

const isLastLargeMedia = computed(() => {
  return selectedLargeMediaId.value === mediaItems.value.length - 1;
});
// METHODS
function handleGalleryHorizontalScroll() {
  const galleryListRef = toValue(galleryList);
  const galleryRightMargin = galleryListRef.clientWidth + galleryListRef.scrollLeft;
  const galleryTooShort = galleryListRef.scrollWidth <= galleryListRef.clientWidth;
  galleryAtEnd.value = galleryTooShort || galleryRightMargin >= galleryListRef.scrollWidth;
}

function handleGalleryNavAction() {
  if (galleryAtEnd.value) {
    toggleGalleryViewAll(true);
  } else {
    handleGalleryScrollRight();
  }
}

function handleGalleryScrollRight() {
  galleryList.value.scrollBy({
    left: 300,
    top: 0,
    behavior: 'smooth',
  });
  handleGalleryHorizontalScroll();
}

function toggleGalleryViewAll(value) {
  if (value) {
    $tracking?.onImageGalleryViewed('header');
  } else {
    $tracking?.onImageGalleryExit();
  }
  showGalleryModal.value = value;
}

function openLightbox(imageId) {
  if (isSmallOrMediumScreen.value) {
    return;
  }
  selectedLargeMediaId.value = imageId;
  selectedLargeMedia.value = mediaItems.value[imageId];
  showLargeMedia.value = true;
}
function closeLightbox() {
  showLargeMedia.value = false;
  selectedLargeMedia.value = null;
  selectedLargeMediaId.value = null;
}
</script>

<template>
  <component
    :is="tag"
    class="gallery-wrapper"
  >
    <div
      ref="galleryList"
      class="gallery-list"
      @scroll="handleGalleryHorizontalScroll"
      @click="toggleGalleryViewAll(true)"
    >
      <PropertyHwImageGalleryPictureGrid
        v-for="(image, iKey) in imageList.slice(0, propertyConfig.galleryMaxDisplayPictures)"
        :key="`gimage${iKey}`"
        :image-obj="image"
        :image-alt="$t('t_PHOTOSOFPROPERTYNAME', { PROPERTYNAME: propertyName })"
        class="gallery-picture"
      />
    </div>
    <div class="property-share">
      <PropertyHwShare />
    </div>
    <div class="gallery-actions">
      <component
        :is="galleryAtEnd ? WdsButtonGhost : WdsPillFloating"
        class="gallery-action"
        v-bind="getGalleryNavActionData"
        @click="handleGalleryNavAction"
      />
    </div>
    <WdsPopup
      v-if="showGalleryModal"
      class="gallery-popup"
      is-open
      :apply-sheet="isSmallOrMediumScreen"
      :head-divider="true"
      close-button
      @popup-close="toggleGalleryViewAll(false)"
    >
      <template #popupContent>
        <ul class="gallery-mosaic">
          <li
            v-for="(media, idx) in mediaItems"
            :key="`gmedia${idx}`"
          >
            <PropertyHwImageGalleryVideoGrid
              v-if="media.still"
              :video-obj="media"
              :video-params="propertyConfig.videoPlayerParams"
            />
            <PropertyHwImageGalleryPictureGrid
              v-else
              :image-obj="media"
              :image-alt="$t('t_PHOTOSOFPROPERTYNAME', { PROPERTYNAME: propertyName })"
              class="gallery-mosaic-item"
              @click="openLightbox(idx)"
            />
          </li>
        </ul>
      </template>
    </WdsPopup>
    <div
      v-if="showLargeMedia"
      class="gallery-lightbox"
    >
      <div
        ref="lightBoxModal"
        class="gallery-lightbox-container"
      >
        <WdsPillFloating
          v-if="!isFirstLargeMedia && isLargeScreen"
          class="next"
          variation="ghost"
          aria-label="Next"
          icon-start="chevron-left"
          @click="openLightbox(selectedLargeMediaId - 1)"
        />
        <PropertyHwImageGalleryVideoGrid
          v-if="selectedLargeMedia.still"
          :video-obj="selectedLargeMedia"
          :video-params="propertyConfig.videoPlayerParams"
        />
        <PropertyHwImageGalleryPictureGrid
          v-else
          :image-obj="selectedLargeMedia"
          :is-light-box="true"
          :image-alt="$t('t_PHOTOSOFPROPERTYNAME', { PROPERTYNAME: propertyName })"
        />
        <WdsPillFloating
          v-if="!isLastLargeMedia && isLargeScreen"
          class="next"
          variation="ghost"
          aria-label="Next"
          icon-start="chevron-right"
          @click="openLightbox(selectedLargeMediaId + 1)"
        />
      </div>
      <WdsIcon
        asset="close"
        class="close-lightbox"
        @click="closeLightbox"
      />
    </div>
  </component>
</template>

<style scoped lang="scss">
.gallery-wrapper {
  position: relative;

  .gallery-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: $wds-spacing-s;
    overflow-x: auto;
    border-radius: $wds-spacing-l 0 0 $wds-spacing-l;

    @include no-scrollbar;

    .gallery-picture {
      cursor: pointer;
      height: wds-rem(220px);
    }
  }

  .property-share {
    position: absolute;
    right: $wds-spacing-s;
    bottom: -$wds-spacing-m;
  }

  .gallery-actions {
    display: none;
  }

  .gallery-popup {
    :deep(.modal-container) {
      overflow: clip;
      width: 100%;

      @include desktop {
        max-width: wds-rem(1600px) !important;
      }

      .modal-body {
        padding: 0 $wds-spacing-s !important;
      }
    }
  }

  .gallery-lightbox {
    position: fixed;
    z-index: 1000;
    padding: $wds-spacing-m;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0 0 0 / 90%);
    display: flex;

    &-container {
      display: flex;
      margin: auto;
      max-height: 90%;
      align-items: center;
      gap: $wds-spacing-m;

      :deep(.pill-content) {
        width: wds-rem(40px);
        height: wds-rem(40px);
      }

      iframe {
        position: unset;
        width: 100%;
        height: wds-rem(450px);
      }

      :deep(picture) {
        max-width: calc(100% - 7rem);
      }
    }

    :deep(.close-lightbox) {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
      fill: $wds-color-white;
    }
  }

  .gallery-mosaic {
    columns: 15rem;
    column-gap: $wds-spacing-s;
    list-style: none;
    margin: 0;
    padding-bottom: $wds-spacing-l;

    @media screen and (width >= 1024px) {
      columns: 20rem;
    }

    li {
      break-inside: avoid;
      margin: 0 0 $wds-spacing-s;
      overflow: hidden;

      :deep(picture) > img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: transform 1.5s ease;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    iframe {
      position: unset;
      width: 100%;
      height: wds-rem(220px);
      border: 0;

      @media screen and (width >= 768px) {
        height: wds-rem(240px);
      }
    }
  }

  @include tablet {
    .property-share {
      right: $wds-spacing-l;
    }
  }

  @include tablet-large {
    .gallery-list .gallery-picture {
      height: wds-rem(240px);
    }
  }

  @include desktop {
    .gallery-actions {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: wds-rem(170px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90deg, rgb(241 242 244 / 0%) 0%, #f1f2f4 98%);

      .gallery-action {
        background-color: white;
      }
    }
  }

  @include desktop-large {
    .gallery-list {
      .gallery-picture {
        height: wds-rem(320px);
      }
    }

    .gallery-actions {
      right: wds-rem(23px);
    }
  }
}
</style>
