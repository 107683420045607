<script setup>
import propertyConfig from '@/config/property.js';
import { WdsButtonLink } from '@wds/components';

defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
  description: {
    type: String,
    required: true,
  },
});

// EVENTS
const emit = defineEmits(['viewMore']);

const { isBelowMediumScreen } = useMq();

const propertyDescriptionConfigs = propertyConfig.descriptionTruncateLength;

const descriptionLength = computed(() =>
  toValue(isBelowMediumScreen) ? propertyDescriptionConfigs.SHORT : propertyDescriptionConfigs.LONG,
);

function toggleAboutModal() {
  emit('viewMore', 'about');
}
</script>

<template>
  <component :is="tag">
    <h2 class="about-title">
      {{ $t('t_ABOUT') }}
    </h2>
    <div class="about-description">
      <div
        :class="{ 'line-clamp': descriptionLength }"
        :style="{ 'clamp-lines': descriptionLength, '-webkit-line-clamp': descriptionLength }"
      >
        <p
          class="text"
          v-html="description"
        />
      </div>
      <WdsButtonLink
        :text="$t('t_READMORE')"
        class="about-read-more"
        icon-end="chevron-right"
        @click="toggleAboutModal()"
      />
    </div>
  </component>
</template>

<style lang="scss" scoped>
.about-description {
  padding-bottom: $wds-spacing-s;

  .line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;

    .text {
      @include body-2-reg;

      white-space: pre-line;
    }
  }

  .about-read-more {
    cursor: pointer;
    margin-left: auto;
    margin-top: $wds-spacing-m;
  }
}
</style>
