export default {
  shareOptions: [
    {
      label: 't_COPYLINK',
      value: 'clipboard',
      icon: 'copy',
    },
    {
      label: 't_EMAILSHARE',
      value: 'mail',
      icon: 'envelope',
    },
    {
      label: 't_WHATSAPP',
      value: 'whatsapp',
      icon: 'sn-whatsapp',
    },
    {
      label: 't_MESSENGER',
      value: 'fb_messenger',
      icon: 'sn-facebook',
    },
  ],
};
