export default {
  galleryMaxDisplayPictures: 7,
  descriptionTruncateLength: {
    LONG: 15,
    SHORT: 10,
  },
  videoPlayerParams: {
    height: '80%',
    width: '100%',
    disableKb: 1,
    color: 'white',
    ivLoadPolicy: 0,
    allowFullscreen: 1,
  },
};
