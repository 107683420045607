<script setup>
const props = defineProps({
  imageObj: {
    type: Object,
    required: true,
  },
  imageAlt: {
    type: String,
    required: true,
  },
  isLightBox: {
    type: Boolean,
    default: false,
  },
});

// COMPOSABLES
const { useImage } = useImages();

// CONSTANTS
const PICTURE_DEFAULT_OPTIONS = {
  vibrance: true,
  sharpen: true,
  improve: true,
};

const PICTURE_OPTIONS = {
  large: {
    height: 320,
    width: 1024,
  },
  medium: {
    height: 320,
    width: 768,
  },
  small: {
    height: 240,
    width: 416,
  },
};

function getPictureOptions(size) {
  const imageDimensions = props.isLightBox
    ? { width: PICTURE_OPTIONS[size].width }
    : { height: PICTURE_OPTIONS[size].height };
  return {
    ...PICTURE_DEFAULT_OPTIONS,
    ...imageDimensions,
  };
}
</script>

<template>
  <picture>
    <source
      media="(min-width: 1368px)"
      :srcset="useImage.getOptimizedImageUrl(imageObj?.large, getPictureOptions('large'))"
    />
    <source
      media="(min-width: 768px)"
      :srcset="useImage.getOptimizedImageUrl(imageObj?.medium, getPictureOptions('medium'))"
    />
    <source
      media="(max-width: 768px)"
      :srcset="useImage.getOptimizedImageUrl(imageObj?.small, getPictureOptions('small'))"
    />
    <img
      src="#"
      :alt="imageAlt"
      loading="lazy"
    />
  </picture>
</template>

<style scoped lang="scss">
img {
  max-width: unset;
  height: 100%;
}
</style>
